<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <div class="text-xs-center">
                            <p class="subheading mb-0">{{ $t('takeout.use-cabinet-scanner') }}</p>
                            <BoxQRCode :content="qrContent" :key="qrcodeKey"></BoxQRCode>
                            <p>
                                {{ $t('takeout.wait-a-while') }}{{ $t('takeout.box-screen') }}<v-icon color="green">check</v-icon>{{ $t('takeout.no-response-press-button') }}
                                <v-btn fluid class="pa-0" fab dark color="green" @click="getBoxInformation"><v-icon>search</v-icon></v-btn>
                            </p>
                        </div>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-1 pt-2">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <package-list
                            :voiceMsg="false"
                            :allowSelectAll="oneDoor()"
                            :packages="packages"
                            @confirm="confirmSelection"
                            @back="setStepNo(1)"
                        ></package-list>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <v-expansion-panel popout>
                            <v-expansion-panel-content class="cyan lighten-5">
                                <template v-slot:header>
                                    <div class="subheading">
                                        {{ $t('takeout.details') }}
                                    </div>
                                </template>
                                <v-list subheader two-line class="cyan lighten-5">
                                    <v-list-tile v-for="p in selectedPackages" :key="p.track_no">
                                        <v-list-tile-content>
                                            <v-list-tile-title>{{ $t('takeout.cell') }}&nbsp;{{ p.door_id }}</v-list-tile-title>
                                            <v-list-tile-sub-title>{{ $t('takeout.cost') }}&nbsp;{{ finalAmount(p) }}&nbsp;{{ $t('takeout.NTD') }}</v-list-tile-sub-title>
                                            <v-list-tile-sub-title>{{ $t('takeout.storage-time') }}&nbsp;{{ p.putin.time }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                </v-list>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <payment-info
                            :enable="stepNo === 3"
                            :amount="totalAmount"
                            :boxId="boxId"
                            :doorId="doorIds"
                            :period=600
                            :note="$t('takeout.complete-payment')"
                            @refresh="getPackages"
                        ></payment-info>
                        <payment-selector
                            :enable="stepNo === 2"
                            :amount="totalAmount"
                            :doorId="doorIds"
                            :transType="transtype"
                            :action="action"
                            @back="setStepNo(2)"
                            @pay="openDoors"
                            ref="paymentSelector"
                        ></payment-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list two-line subheader>
                            <v-subheader> {{ $t('takeout.get') }} {{ takeoutPackages.length }} {{ $t('takeout.package') }} </v-subheader>
                            <v-list-tile avatar v-for="(p, index) in takeoutPackages" :key="p.track_no">
                                <v-list-tile-content>
                                    <v-list-tile-title class="title">{{ $t('takeout.cell') }} {{ p.door_id }}</v-list-tile-title>
                                    <v-list-tile-sub-title v-show="p.message" class="error--text">{{ p.message }}</v-list-tile-sub-title>
                                    <v-list-item>{{ $t('takeout.user-name') }} {{ p.putin.user_name }}, {{ $t('takeout.user-phone')}} {{ p.putin.user_phone }}</v-list-item>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-btn v-if="p.openable" dark color="cyan" class="subheading" @click="reopen(index)">{{ $t('takeout.reopen') }}</v-btn>
                                    <v-chip v-else label diabled outline color="error" class="subheading">{{ $t('takeout.can-not-open') }}</v-chip>
                                </v-list-tile-action>
                            </v-list-tile>
                        </v-list>
                        <v-btn large block class="subheading" color="primary" @click="leave()">{{ $t('takeout.done') }}</v-btn>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
            ></message-dialog>
            <v-dialog persistent v-model="emptyDialog" width="250">
                <v-card class="text-xs-center">
                    <v-card-text  class="title">
                        {{ dialogMessage }}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn block class="subheading" color="primary" @click="leave()"> {{ $t('takeout.back') }} </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import BoxQRCode from '@/components/BoxQRCode'
import WaitingDialog from '@/components/WaitingDialog'
import PaymentInfo from '@/components/payments/PaymentInfo'
import PaymentSelector from '@/components/payments/PaymentSelector'
import PackageList from '@/components/PackageList'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { publish } from '@/utils/MQTTClient'
import { TransTypes } from '@/mixins/PutinMixin'
import i18n from '@/i18n'
import { MQTT_ACTION, handleSelectBox } from '@/utils/MQTTClient'

const INTERVAL_BTN_FEE_AND_CONTROL = 60;

export default {
    name: 'docsClerkTakeout',
    components: { BoxQRCode, WaitingDialog, PaymentSelector, PaymentInfo, PackageList, MessageDialog },
    mixins: [ BoxIdWatcherMixin, ControlResultWatcher, WaitDialogControl, MessageDialogControl ],
    data() {
        store.commit('setPageHome', 3)
        return {
            titles: [i18n.t('takeout.pickup-QR-code'), i18n.t('takeout.pick-list'), i18n.t('takeout.pick-list')],
            stepNo: 1,
            qrContent: { action: 'auth' },
            qrcodeKey: 0,
            notify: false,
            packages: [],
            emptyDialog: false,
            billingTime: new Date().getTime(),
            takeoutPackages: [],
            selectTrackNos: null,
            transtype: TransTypes.DocsClient,
            zero_stamp: 0,
            action: MQTT_ACTION.TAKEOUT_ACTION,
            dialogMessage: i18n.t('takeout.no-package'),
            message: i18n.t('takeout.line-pay-transaction')
        }
    },
    watch: {
        boxId() {
            if (this.boxId) {
                if (store.getters.user.box_owner != null && !store.getters.user.box_owner.includes(this.boxId)) {
                    store.commit('setSnackBar', { message: this.boxId + ' ' + i18n.t('doorSelector.cabinet-not-set'), color: 'error' })
                    this.leave()
                    return
                }
                var self = this
                self.getPackages(function (packages) {
                    if (packages.length === 0) self.emptyDialog = true
                    else if (packages.length === 1) {
                        if (self.zero_stamp === 0)
                            self.zero_stamp = Date.parse(new Date())/1000
                        self.openDoors()
                    }
                    else self.setStepNo(2)
                })
            }
        },
        controlResult() {
            this.closeDialog()
            this.updatePackagesWithControlResult()
            this.setStepNo(4)
        }
    },
    computed: {
        selectedPackages() {
            return this.packages.filter(p => p.track_no in this.selectTrackNos)
        },
        doorIds() {
            return this.selectedPackages ? this.selectedPackages.map(function(e) { return e.door_id }) : []
        },
        totalAmount() {
            if (this.selectedPackages) return this.selectedPackages.reduce((s, p) => s + this.finalAmount(p), 0)
            else return 0
        }
    },
    methods: {
        initAll() {
            store.commit('initTransaction');
            store.commit('setTitle', this.titles[0]);
            store.commit('setTargetBoxId', '');
            store.commit('setReopenTimeLimit', false);
            store.commit('setMQTTPayload', null);
            this.boxId = ''
            this.stepNo = 1
            this.emptyDialog = false
            this.packages = []
            this.takeoutPackages = []
            this.selectTrackNos = null
            this.zero_stamp = 0
            store.commit('setIsFromLinePay', false)
        },
        reloadqrcode(){
            store.commit('setClientId')
        },
        setStepNo: function(no) {
            if (no === 1) this.initAll();
            this.stepNo = no;
            store.commit('setTitle', this.titles[this.stepNo - 1]);
            this.closeDialog()
        },
        async getPackages(handler = null) {
            this.showDialog(i18n.t('takeout.get-list'), i18n.t('takeout.fail-get-list'), 15000)
            this.billingTime = new Date().getTime()
            let url = `${HostUrl}/api/box/${this.boxId}/clerk/${this.transtype}/takeout`
            try {
                let response = await axios.get(url, getAxiosConfig())
                if (response.data.code == 0) {
                    this.packages = response.data.packages
                    if (this.selectTrackNos === null) {
                        this.selectTrackNos = {}
                        this.packages.forEach(p => { this.selectTrackNos[p.track_no] = p.type })
                    }
                } else throw new Error(response.data.message)
            } catch (error) {
                store.commit('setSnackBar', { message: error.message, color: 'error' })
            } finally {
                this.closeDialog()
            }
            // 於取得新的 Packages 列表後執行 handler hook
            // 用於首次進來此頁面根據 package 內容判斷要跳到哪一頁的動作
            if (handler) handler(this.packages)
        },
        oneDoor() {
            if (this.doorId <= 0 || (this.packages && this.packages.length > 1)) {
                if (this.packages.length === 1) {
                    return true
                }
                else return false
            }
            else return true
        },
        confirmSelection(selections) {
            this.selectTrackNos = selections
            if (this.totalAmount === 0) {
                if (this.zero_stamp === 0)
                    this.zero_stamp = Date.parse(new Date())/1000
                this.openDoors()
            }
            else this.setStepNo(3)
        },
        finalAmount(p) {
            return p.amount
        },
        openDoors() {
            let url = ""
            let timestamp = Date.parse(new Date())/1000
            let payload = {
                client_id: store.getters.clientId,
                track_no: this.selectTrackNos
            }
            if ((this.zero_stamp+INTERVAL_BTN_FEE_AND_CONTROL) > timestamp) {
                url = `${HostUrl}/api/box/${this.boxId}/clerk/${this.transtype}/takeout/control`;
            } else {
                store.commit('setSnackBar', { message: '[' + i18n.t('takeout.transaction-failed') + ']: ' + i18n.t('takeout.use-cabinet-scanner'), color: 'error' })
                this.initAll()
                return
            }
            this.showDialog(i18n.t('takeout.open-cell'), i18n.t('takeout.fail-open-cell'), 30000)
            var self = this
            axios.post(url, payload, getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        if(response.data && response.data.payment_url && response.data.payment_url.web) {
                            window.location.href = response.data.payment_url.web
                        } else {
                            self.notify = response.data.notify
                            self.takeoutPackages = response.data.packages
                        }
                        if (!self.takeoutPackages) self.emptyDialog = true
                    } else {
                        self.closeDialog()
                        store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                    }
                })
                .catch(function () {
                    self.closeDialog()
                    store.commit('setSnackBar', { message: i18n.t('takeout.transaction-fail'), color: 'error' })
                })
        },
        reopen(index) {
            //限制不得超過50秒後按重開按鍵
            let overTime = store.getters.mqttPayload + 51000
            let currentTime = new Date().getTime()
            if(currentTime > overTime) {
                this.showMsgDialog(i18n.t('takeout.cannot-taken-out'), i18n.t('takeout.close'))
                store.commit('setReopenTimeLimit', false)
                return
            }
            this.showDialog(i18n.t('takeout.open-cell'), i18n.t('takeout.fail-open-cell'), 30000)
            var topic = `box/${this.boxId}/trans/control`
            let p = this.takeoutPackages[index]
            let transtype = this.selectTrackNos[p.track_no]
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                action: MQTT_ACTION.TAKEOUT_ACTION,
                track_no: { [p.track_no] : {
                    door_id: p.door_id,
                    trans_type: transtype
                }}
            }, 1)
        },
        updatePackagesWithControlResult: function() {
            this.takeoutPackages.filter(p => p.track_no in this.controlResult).forEach(p => {
                if (this.controlResult[p.track_no] < 0) p.message = i18n.t('takeout.fail-open');
                else p.message = ''
            })
            store.commit('setReopenTimeLimit', true)
        },
        leave() {
            this.initAll();
            this.$router.push(`/${i18n.locale}/docshost/menu/`);
        },        
        async getBoxInformation() {
            this.showDialog(i18n.t('takeout.get-list'), i18n.t('takeout.fail-get-list'), 15000)
            let url = `${HostUrl}/api/user/token/checkboxid`
            let payload = store.getters.userInfo
            try {
                let response = await axios.post(url, payload, getAxiosConfig())
                if (response.data.code == 0) {
                    handleSelectBox(response.data)
                } else throw new Error(response.data.message)
            } catch (error) {
                if (error.message === "請掃描 QR code") {
                    error.message = i18n.t('takeout.scan-QR-code')
                }
                store.commit('setSnackBar', { message: error.message, color: 'error' })
            } finally {
                this.closeDialog()
            }
        }
    },
    activated() {
        store.commit('setSideBtnType', SideBtnType.Back)
        this.initAll()
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
};
</script>

<style scoped>
</style>
